
import {computed, defineComponent, ref, watch} from 'vue'
import BaseForm from "@/components/base/form/BaseForm.vue";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import {Field} from "vee-validate";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import {ILocation} from "@/core/entity/ILocation";
import store from "@/store";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import BaseMultiSelect from "@/components/base/select/BaseMultiSelect.vue";
import * as Yup from "yup";
import SanctionCheckService from "@/core/services/SanctionCheckService";

export default defineComponent({
  name: "SanctionCheckForm",
  components: {BaseMultiSelect, BaseSelect, BaseRadio, FormErrorMessage, BaseForm, Field},
  props: {
    data: {type: Object}
  },
  emits: ['saved'],
  setup(props) {
    const submitting = ref(false);
    const model = ref<any>(props.data);
    watch(() => props.data, cb => {
      model.value = cb;
    })
    const validate = Yup.object().shape({
      name: Yup.string().required().max(255).label('Name'),
    })
    const countries = computed<ILocation[]>(() => store.state.LocationModule.countries)
    return {
      validate,
      model,
      countries,
      submitting,

    }

  },
  methods: {
    onSubmit() {
      this.submitting = true;
      SanctionCheckService.create(this.model).then(res => {
        this.$emit('saved', res);
      }).finally(() => {
        this.submitting = false;
      })
    }
  }
})
