
import {defineComponent, ref, watch} from 'vue'
import BaseForm from "@/components/base/form/BaseForm.vue";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import SanctionCheckService from "@/core/services/SanctionCheckService";

export default defineComponent({
  name: "SanctionDecisionForm",
  components: {BaseRadio, FormErrorMessage, BaseForm},
  props: {
    id: {},
    request: {required: true}
  },
  emits: ['decisionSaved'],
  setup(props) {
    const model = ref(props.request)
    watch(() => props.request, cb => {
      model.value = cb;
    })
    const submitting = ref(false);
    return {
      submitting,
      model,

    }
  },

  methods: {
    submit() {
      this.submitting = true;
      SanctionCheckService.decision(this.id, this.model).then(res => {
        this.$emit('decisionSaved', res);
      }).finally(() => {
        this.submitting = false
      })
    }
  }
})
