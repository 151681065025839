
import {computed, defineComponent, onMounted, ref} from 'vue'
import store from "@/store";
import Entities from "@/components/base/common/Entities.vue";
import RecentCards from "@/components/base/common/RecentCards.vue";
import {LoadFilterObjects} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import SanctionCard from "@/views/sanction-check/SanctionCard.vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import QuickAction from "@/components/base/action/QuickAction.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import SanctionCheckForm from "@/views/sanction-check/SanctionCheckForm.vue";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import {Field} from "vee-validate";
import {ILocation} from "@/core/entity/ILocation";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import SanctionDecisionForm from "@/views/sanction-check/SanctionDecisionForm.vue";

export default defineComponent({
  name: "List",
  components: {
    SanctionDecisionForm,
    BaseSelect,
    BaseRadio, SanctionCheckForm, BaseModal, QuickAction, SanctionCard, RecentCards, Entities, Field
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('Sanction Checks',
        [
          {link: false, router: '', text: 'Sanction Checks'}
        ]
      )
    })
    const page = computed(() => store.state.SanctionCheckModule.page)
    const objectFilters = ref({name: '', country: '', year: '', entityType: ''});
    const model = ref<any>({name: '', country: '', yearOfBirth: '', entityType: '', minScore: 0.88, dataSource: []});
    const countries = computed<ILocation[]>(() => store.state.LocationModule.countries)
    const decisionStateRequest = ref({id: '', model: {decision: '', comment: ''}})
    return {
      page,
      countries,
      model,
      decisionStateRequest,
      objectFilters,
      ...LoadFilterObjects(Actions.LOAD_SANCTION_CHECK, objectFilters.value, [])
    }
  },
  methods: {
    onSearch() {
      this.model = {
        name: '',
        country: '',
        yearOfBirth: '',
        entityType: '',
        minScore: 0.88,
        dataSource: ['FBI', 'INTERPOL', 'CRIME', 'SANCTIONS-LISTS']
      }
      const modal = this.$refs.sanctionCheckRef as typeof BaseModal;
      modal.showBaseModal();
    },
    onSaved() {
      this.paginationLoad()
      const modal = this.$refs.sanctionCheckRef as typeof BaseModal;
      modal.hideBaseModal();
    },
    onDiscard() {
      this.objectFilters = {name: '', country: '', year: '', entityType: ''}
      this.paginationLoad()
    },
    onSearchFilter() {
      this.updateFilterObject(this.objectFilters)
    },
    onMakeDecision(item) {
      this.decisionStateRequest.id = item.id
      console.log(item);
      this.decisionStateRequest.model = {
        decision: item.decision ? item.decision : '',
        comment: item.decisionComment ? item.decisionComment : ''
      }
      const modal = this.$refs.sanctionCheckDecisionRef as typeof  BaseModal
      modal.showBaseModal();
    },
    onDecisionSaved() {
      const modal = this.$refs.sanctionCheckDecisionRef as typeof  BaseModal
      modal.hideBaseModal();
      this.paginationLoad()
    }
  }
})
