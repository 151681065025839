
import {defineComponent} from 'vue'
import Row from "@/components/base/common/Row.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import Swal from "sweetalert2";
import SanctionCheckService from "@/core/services/SanctionCheckService";
import {LoadPanel} from "@/core/composite/composite";
import SanctionDecisionStatus from "@/views/sanction-check/SanctionDecisionStatus.vue";

export default defineComponent({
  name: "SanctionCard",
  components: {SanctionDecisionStatus, QuickAction, Row},
  props: {
    sanction: {type: Object, required: true}
  },
  setup() {
    return {...LoadPanel()}
  },
  emits: ['deleted', 'decision'],
  methods: {
    onDecision(item) {
      this.$emit('decision', item);
    },
    onDelete() {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await SanctionCheckService.delete(this.sanction.id).then(() => {
            this.$emit('deleted')
          })
        }
      })
    }
  }
})
